/*
Theme Name: Healthcare Associates of Texas Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Healthcare Associates of Texas
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1 0 auto; /* 2 */
  width: 100%;
  position:relative;
  
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff url(images/bg.png) repeat 0 0;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
}

#body-overlay{
	background:#fff;
	width:100%;
	height:100%;
	.centered();
	position: fixed;
	top:0;
	left:0;
	z-index: 9999;
	svg{
		.inline-block();
		overflow: visible;
		position: absolute;
		top:50%;
		transform:translateY(-50%);
		width:80px;
		height: auto;
		opacity:0;
		.star-parts{
			opacity: 0.1;
			}
		}
	}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:50px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:0 40px 20px 0;
    line-height:1.7;
    border-right: 2px solid @beigeLight;
    a{
    	color:@aqua;
    	&:hover{
    		color:@red;
    		}
    	}
    ul{
        padding:10px 0 10px 20px;
        >li{
            position: relative;
            padding:0 0 0 20px;
            margin-bottom:12px;
            >i{
            	position: absolute;
            	left:0;
            	top:7px;
            	font-size: 14px;
            	}
            }
        }
    img{
        border:4px solid @grayLight;
        
        }
    h2{
    	color:@blue;
    	font-weight: 300;
    	margin:0 0 20px 0;
    	.poppins(45px);
    	}
    h3{
    	color:@aqua;
    	font-weight: 300;
    	margin:10px 0 20px 0;
    	.poppins(32px);
    	}
    h4{
    	color:@red;
    	font-weight: 700;
    	margin:10px 0 20px 0;
    	.poppins(24px);
    	.uppercase();
    	letter-spacing: .04em;
    	}
    &.wide{
    	padding:10px!important;
    	border:none;
    	}
    &.full{
    	padding:0px!important;
    	border:none;
    	}
    }
	
#sidebar{
	padding:0 0 0 40px;
	line-height: 1.6;
	.subpage-menu{
		
		}
	}
	
.accordion-wrap{
	margin-top:25px;
	}

.toggler{
	.inline-block();
	margin-bottom:2px;
	width:90%;
	.trigger{
		background:@aqua;
		position:relative;
		padding:25px 40px 25px 65px;
		.transition();
		text-align: left;
		.icon{
			height: 100%;
			position: absolute;
			left:0;
			top:0;
			background: @blueDark url(images/map-pin-white.svg) no-repeat center center;
			background-size: 40% auto;
			.rounded-corners(50%);
			border:2px solid #fff;
			transform: translateX(-50%);
			.transition();
			display: block;
			&.service{
				background: @blueDark url(images/clipboard.svg) no-repeat center center;
				background-size: 70% auto;
				}
			}
		i{
			color:#fff;
			font-size:28px;
			position: absolute;
			right:20px;
			top:50%;
			transform:translateY(-50%);
			}
		&:hover, &.active{
			cursor:pointer;
			background:@red;
			}
		&.active{
			background:@red;
			span.icon{
				background: @blue url(images/map-pin-white.svg) no-repeat center center;
				background-size: 40% auto;
				&.service{
				background: @blue url(images/clipboard.svg) no-repeat center center;
				background-size: 70% auto;
				}
				}
			}
		
		h5{
			color:#fff;
			.poppins(26px);
	
			letter-spacing:.02em;
			line-height:1.3;
			}
		}
	.toggle_container{
		overflow:hidden;
		background:@beigeLight;
		height:0;
		&.transparent{
			background:transparent;
			}
		.block{
			opacity:0;
			
			padding:30px;
			&.left{
				text-align: left;
				}
			}
		}
	}
	
ul.subpage-menu{
    width:100%;
    padding:0!important;
    margin:30px 0 30px 0!important;
    >li{
        display:block;
        margin:0 0 8px 0!important;
        padding:0;
        i{display: none;}
        >a{
            display:block;
            padding:10px;
            .centered();
            .poppins(30px);
            .uppercase();
            .bold();
            border: 2px solid @beigeLight;
            .transition(all linear 130ms);
            font-size:20px;
            .scale(1);
            color:@blue!important;
            &:hover{
                background:@aqua;
                border:2px solid @aqua;
                color:#fff!important;
                .scale(1.03);
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
	&.sidebar{
		padding-top:0!important;
		margin-top:0!important;
		>li{
			margin:0!important;
			>a{
				border:none!important;
				border-bottom:2px solid @beigeLight!important;
				.poppins(20px);
				font-weight: 300!important;
				&:hover{
					border-bottom:2px solid @aqua!important;
					}
				}
			&.current_page_item>a, &.current_page_ancestor>a{
                background:@aqua!important;
                color:#fff!important;
                border-bottom: 2px solid @aqua!important;
                }
			}
		}
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

ul.term-list{
	
	}

#footer {
	position: relative;
	width:@mainWidth;
	background:@blueDark;
	color:#fff;
	clear:both;

	} 
	
.footer-content{
	text-align:center;
	padding:80px 0 80px 0;
	position: relative;
	.poppins(16px);
	overflow: hidden;
	.left,.middle,.right{
		padding:0 30px 30px 30px!important;
		}
	a{
		color:#fff;
		&:hover{
			color:@aqua;
			}
		}
		.watermark-svg{
		height:110%;
		width:auto;
		position: absolute;
		left:-5%;
		top:-5%;
		}
	.left{
		span{display: block;}
		
		}
	.left,.right{
		padding-top:30px!important;
		}
	.logo-svg{
		path,polygon{fill:#fff!important;}
		}
	.social-menu{
		li{
			font-size: 40px;
			margin:0 10px 0 10px;
			&:hover{
				color:@aqua;
				.scale(1.1);
				}
			}
		}
	#copyright{
		.inline-block();
		width:90%;
		max-width:800px;
		.centered();
		border-top:2px solid @blue;
		padding-top:30px;
		.poppins(11px);
		.uppercase();
		letter-spacing: .1em;
		}
	}

.slide-in{
	.opacity(0);
	transform:translateY(30px);
	}
	
.section{
    width:100%;
    position:relative;
    min-height:200px;
    padding:20px 0 20px 0;
    line-height: 1.7;
    &.no-pad{
    	padding:0!important;
    	}
    h5.section-head{
    	.poppins(48px);
    	color:@blueDark;
    	font-weight: 300;
    	margin-bottom: 30px;
    	line-height: 1.2;
    	strong{
    		font-weight: 600;
    		
    		}
    	&.white{
    		color:#fff;
    		}
    	&.shadowed{
    		.text-shadow();
    		}
    	}
    &.padded{
    	padding:80px 0 80px 0;
    	}
    }
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .uppercase();
    position:relative;
    padding:0 60px 0 60px;
    .centered();
    .quote{
        display:block;
        width:50px;
        height:50px;
        position:absolute;
        &.open{
            top:0;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        &.close{
            bottom:0;
            right:0;
            background:url(images/close-quote-callout.png) no-repeat 0 0;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:1px solid #d9d8d3;
        border-bottom:1px solid #d9d8d3;
        padding:15px 20px 15px 20px;
        margin:10px 0 10px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    height:auto;
    background:transparent;
    .transition();
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    z-index: 9998;
    padding-bottom: 30px;
    &.active{
    	background:@blueDark;
    	.box-shadow-custom(0px,1px,3px,rgba(0,0,0,0.3));
    	}
    .central{
    	padding:0!important;
    	}
    #top-bar{
    	background: @aqua;
    	padding: 10px;
    	border-bottom: 1px solid #fff;
    	color: #fff;
    	margin-bottom:25px;
    	text-align: right;
    	#menu-top-navigation, ul.social-menu{
    		.inline-block();
    		vertical-align: middle;
    		}
    	#menu-top-navigation{
    		
    			>li{
    				font-size: 12px;
    				margin-right:12px;
    				&:before{
    					color:@blue;
    					margin-right:5px;
    					
    					}
    				>a{
    					.poppins(12px);
    					color:#fff;
    					font-weight: 600!important;
    					.transition();
    					&:hover{
    						color:@blue;
    						}
    					}
    				}
    
    		}
    	.social-menu{
    		margin-left:15px;
    		>li{
    			vertical-align: middle;
    			font-size:14px;
    			color:@blue;
    			padding:0;
    			line-height: 1;
    			font-weight:600;
    		
    			&:hover{
    				color:#fff;
    				}
    			}
    		}
    	}
    .logo-svg{
    	-webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));      
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));  
    	}
}

#nav{
    
}

#search-bar{
	overflow: hidden;
	background: @blue;
	
	height: 0;
	a#search-close{
		color:#fff;
		width: 5%;
		.centered();
		display: block;
		position: absolute;
		right:0;
		top:0;
		font-size: 24px;
		height: 100%;
		padding-top: 8px;
		opacity:0;
		transform:translateY(-15px);
		&:hover{color:@aqua;}
		}
	.search-form{
		margin:20px 0 20px 0;
		position: relative;
		display: block;
		width:95%;
		opacity:0;
		transform:translateY(-15px);
		input[type=text]{
			display: block;
			width:100%;
			.poppins(16px);
			color: @blue;
			padding:8px;
			outline: none;
			border:none;
			}
		button{
			background:transparent;
			border: none;
			color:@aqua;
			.scale(1);
			position: absolute;
			right:5px;
			top:10px;
			font-size: 20px;
			.transition();
			&:hover{
				cursor: pointer;
				color: @red;
				.scale(1.1);
				}
			}
		}
	}

#menu-main-navigation{
	text-align: right;
    list-style:none;
    padding:25px 0 0 0;
    >li{
        .inline-block();
        position:relative;
        margin-right:3px;
        &:hover>a, &.current_page_item>a, &.current-page-ancestor>a{color:@aqua!important;}
     
        
        >a{
            .poppins(15px);
            font-weight: 600;
            letter-spacing:.01em;
            display:block;
            padding:8px;
            color:#fff;
            .text-shadow();
            border:1px solid rgba(255,255,255,0);
            .transition();
            &:hover{
        	color: @aqua;
            border:1px solid rgba(255,255,255,1);
            }
        
        }
       &.box-link a{
        	color:@aqua;
        	border:1px solid rgba(255,255,255,1);
        	&:hover{
        		color:#fff!important;
        		background:@aqua;
        		border:1px solid @aqua;
        		}
        	}
       &.search-toggle>a{
       	border:none;
       	.scale(1);
       	font-size:18px;
       	&:hover{
       		color:@red!important;
       		.scale(1.3);
       		}
       	}
    }
}

ul.social-menu{
    .inline-block();
    margin:0;
    padding:0;
    li{
        .inline-block();
        color:#fff;
        font-size:30px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        .scale(1);
        .transition();
        &:hover{color:@accent;.scale(1.05);}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button, button.submit{
    .poppins(16px);
    .uppercase();
    .transition();
    display:inline-block;
    letter-spacing: .05em;
    background:transparent;
    padding:15px 20px 15px 20px;
    font-weight: 600;
    color:@aqua!important;
    position:relative;
    border:1px solid #fff;
    .centered();
    .text-shadow();
    i{margin-left:10px;}
    &:hover{
    	cursor: pointer;
        background:@aqua;
        color:#fff!important;
        border:1px solid @aqua;
        }
    &.small{
    	font-size:14px;
    	padding:10px 15px 10px 15px;
    	}
    &.x-small{
    	font-size:12px;
    	padding:10px 15px 10px 15px;
    	}
    &.filled{
    	background:@blue;
    	color:#fff!important;
    	border:1px solid @blue;
    	&:hover{
        background:@aqua;
        color:#fff!important;
        border:1px solid @aqua;
        }
    	}
    &.white{
    	text-shadow:none;
    	border:1px solid @blue!important;
    	&:hover{
    		background:@blue!important;
    		}
    	}
    	
    &.white-line{
    	text-shadow:none;
    	border:1px solid #fff!important;
    	color: #fff!important;
    	i{color:#fff;}
    	&:hover{
    		border:1px solid @blue!important;
    		background:@blue!important;
    		}
    	}
    &.full{
    	display: block;
    	}
    }
    
button.submit{
	border:1px solid @blue!important;
	text-shadow: none!important;
	font-size:18px;
	&:hover{
		background:@blue!important;
		}
	}


/*********HOMEPAGE**********/
a.block{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top:0;
	left:0;
	z-index: 200;
	}

.overlay{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.4);
	}

.border{
	width:100%;
	height: 10px;
	position: absolute;
	top:0;
	left:0;
	background: #fff url(images/border.svg) repeat-x 0 0;
	z-index: 1000;
	&.bottom{
		top:auto;
		bottom:0;
		left:0;
		}
	}

#banner{
    border-bottom:1px solid #fff;
    position:relative;
    &.homepage{
    	.centered();
    	height:100vh;
    	width: 100%;
    	position: relative;
    	overflow:hidden;
    	.overlay{
    		z-index: 10;
    		background:rgba(6,21,30,0.4);
    		}
    	video#banner-video { 
    		z-index: 5;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    min-width: 100%;
			    min-height: 100%;
			    width: auto;
			    height: auto;
			    z-index: -100;
			    -ms-transform: translateX(-50%) translateY(-50%);
			    -moz-transform: translateX(-50%) translateY(-50%);
			    -webkit-transform: translateX(-50%) translateY(-50%);
			    transform: translateX(-50%) translateY(-50%);
			    background: url(images/tour-poster.jpg) no-repeat;
			    background-size: cover; 
			    
			}
    	}
    
    &.subpage{
        background:@accent;
        padding:200px 0 140px 0;
    	position: relative;
        margin-bottom:0;
        border-bottom:1px solid #fff;
        h1{
            .poppins(48px);
        color:#fff;
       font-weight:700!important;
       .text-shadow();
       line-height: 1.2;
       span.light{font-weight:300;}
       letter-spacing: .02em;
       position: relative;
       z-index: 25;
        }
        &:after{
        	content: '';
        	display: block;
        	position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:rgba(0,0,0,0.4);
			display: block;
			z-index: 10;
        	}
        }
    }

.banner-content{
	.inline-block();
	width:100%;
	height: auto;
	max-width: 900px;
	color:#fff;
	position: relative;
	top:50%;
	transform: translateY(-50%);
	z-index:5000;
	.text-shadow();
	.work(28px);
	line-height: 1.3;
	h1{
		.poppins(60px);
		font-weight: 300;
		color: #fff;
		strong{font-weight:600;}
		}
	}
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:-50px;
    z-index:50;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        button{
            text-align:left;
            background:@accent;
            width:12px;
            height:12px;
            border:none;
            outline:none;
            display:block;
            .rounded-corners(50%);
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            &:hover{
                cursor:pointer;
                background:#fff;
                }
            }
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
#services-section{
	padding:80px 0 0 0;
	.central{
		padding:0 80px 0 80px!important;
		}
	#service-item-wrap{
		margin-top:80px;
	>.service-item{
		position: relative;
		.centered();
		padding:100px 10px 100px 10px;
		border-top:2px solid @beigeLight;
		&:nth-child(3n+2){
		border-right:2px solid @beigeLight;
		border-left:2px solid @beigeLight;
		}
	
		>div{
			.icon{
				width: 110px;
				height:110px;
				.rounded-corners(50%);
				.inline-block();
				background:@aqua;
				.centered();
			
				.transition();
				svg{
					width:70%;
					.inline-block();
					position: relative;
					top:50%;
					.transition();
					transform: translateY(-50%) scale(1);
					
					.service-path{
						.transition();
						}
					}
				}
			.title{
				display: block;
				color:@red;
				.poppins(20px);
				font-weight: 600;
				margin-top:20px;
				}
			}
		&:hover{
			background:@beigeLight;
			.icon{
				background:#fff;
				svg{
						transform: translateY(-50%) scale(1.1);
					.service-path{
						fill:@aqua;
						}
					}
				}
			}
		}
	}
}    

#providers-section{
	#provider-section-content{
		height:100%;
		width:75%;
		position: absolute;
		left:0;
		top:0;
		z-index: 200;
		color:#fff;
		padding:0 0 0 60px;
		background:rgba(5,46,72,0.7);
		>div{
			width:90%;
			max-width:900px;
			.inline-block();
			text-align: left;
			transform: translateY(-50%);
			position: relative;
			top:50%;
			h5{color:#fff;}
			}
		}
	}

.provider-rotator-item{
	line-height: 1!important;
	outline: none;
	img{
		display: block;
		width:100%;
		height:auto;
		}
	}

#latest-section{
	padding:80px 0 80px 0;
	.blog-image{
		margin-bottom:20px;
		}
	.blog-content{
		padding-top:0;
		}
	h5{
		padding-top:0;
		}
	}

button.slick-arrow{
		color:@beigeLight;
		font-size:36px;
		border:none;
		background:transparent;
		position: absolute;
		outline: none;
		.transition();
		&.slick-prev{
			top:50%;
			left:20px;
			transform:translateY(-50%) scale(1);
			}
		&.slick-next{
			top:50%;
			right:20px;
			transform:translateY(-50%) scale(1);
			}
		&:hover{
			transform:translateY(-50%) scale(1.1);
			color:@beige;
			cursor: pointer;
			}
		}

#latest-rotator-wrap{
	position: relative;
	}

.latest-rotator-item{
	.blog-content{
		padding:20px;
		}
	}

.blog-image{
	height:320px;
	border:4px solid @grayLight;
	position: relative;
	.date-box{
		.inline-block();
		padding:20px 30px 20px 30px;
		background:@red;
		position: absolute;
		top:0;
		left:0;
		color:#fff;
		.centered();
		span{
			display: block;
			.text-shadow();
			line-height: 1!important;
			&.month{
				.poppins(16px);
				.uppercase();
				font-weight:600;
				margin-bottom: 5px;
				}
			&.day{
				.poppins(30px);
				font-weight:600;
				}
			}
		}
	&.small{
		height:180px;
		.date-box{
		padding:15px 25px 15px 25px;
		span{
			
			&.month{
				.poppins(13px);
				.uppercase();
				font-weight:600;
				margin-bottom: 5px;
				}
			&.day{
				.poppins(22px);
				font-weight:600;
				}
			}
		}
		&.no-photo{
			background-size:80px auto!important;
			}
	}
}

.blog-content{
	h5.blogtitle{
		.poppins(32px);
		color:@aqua;
		line-height: 1.2;
		font-weight: 300;
		padding:20px 0 20px 0;
		}
	&.side{
		h5.blogtitle{
			font-size: 24px;
			color: @red;
			.centered();
			}
		}
	}
	
#locations-section{
	border-top:10px solid @blue;
	.centered();
	
	}

.acf-map{
		&.homepage{
			height: 500px;
			}
		&.page{
			height: 500px;
			}
		padding:0;
		margin:0;
		}
		
.gm-style-iw{
	.poppins(15px);
	.centered();
	padding:10px;
	h6{
		.poppins(18px);
		color:@blue;
		.uppercase();
		margin-bottom:10px;
		}
	a.button{margin-to:10px;}
	}
	
.top-title{
	background:@aqua;
	border:2px solid #fff;
	.inline-block();
	width:90%;
	max-width:800px;
	position: absolute;
	left:50%;
	transform:translateX(-50%);
	outline: none;
	.no-box-shadow()!important;
	top:-34px;
	.poppins(36px);
	line-height: 1.2;
	color:#fff;
	padding: 20px 60px 20px 80px;
	z-index: 100;
	letter-spacing: .04em;
	.icon{
		height:100%;
		position: absolute;
		transform: translateX(-50%);
		top:0;
		left:0;
		img{
			height:100%;
			border:none!important;
			width: auto;
			}
		}
	&:after{
		content: '';
		display: block;
		position: absolute;
		right:-14px;
		top:0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 20px 0 0 12px;
		border-color: transparent transparent transparent #dfded7;
		}
	}
	
a.readmore{
	.inline-block();
	color:@red;
	.work(17px);
	.uppercase();
	.transition();
	.scale(1);
	padding:0;
	i{margin-left:4px;}
	&:hover{
		color:@aqua;
		padding-left:3px;
		}
	}
	
/**********SERVICES*********/

/*******LOCATIONS******/

.location-item{
	padding:20px!important;
	margin-bottom:25px;
	background:@grayLight;
	text-align: left;
	.map{
		.acf-map{
			height:400px;
			width:100%;
			border:1px solid #aaaaaa;
			}
		}
	.details{
		padding-left:30px;
		h6{
			.work(44px);
			color:@blueDark;
			line-height: 1.2;
			margin-bottom: 15px;
			}
		
		}
	}

span.detail{
			display: block;
			position: relative;
			padding-left:30px;
			margin-bottom:15px;
			i{
				color:@blue;
				position: absolute;
				left:0;
				top:6px;
				}
			}
			
.details-box{
	background: @grayLight;
	padding:20px!important;
	}
	
/*******PROVIDERS******/

.provider-item{
	width:100%;
	max-width:300px;
	height:380px;
	.inline-block();
	margin:15px;
	position: relative;
	overflow: hidden;
	z-index: 5;
	.title{
		width:100%;
		height:auto;
		padding:15px;
		.centered();
		position: absolute;
		bottom:0;
		left:0;
		.poppins(20px);
		.bold();
		color:#fff;
		background:rgba(21, 57, 82, 0.5);
		.transition();
		transform:translateX(0);
		}
	.fader{
		width:100%;
		height:100%;
		transform:translateX(100%);
		background:rgba(21, 57, 82, 0.5);
		color:#fff;
		.transition();
		z-index: 1;
		span{
			color:#fff;
			.poppins(20px);
			.bold();
			.uppercase();
			.inline-block();
			padding:10px;
			position: relative;
			top:50%;
			.transition();
			transform: translateY(-50%);
			i{margin-right:10px;}
			z-index:900;
			&:hover{
				
				background:@aqua;
				border:1px solid @aqua;
				}
			}
		}
	&:hover{
		.title{
			transform:translateX(-100%);
			}
		.fader{
			transform:translateX(0);
			}
		}
	&.small{
		max-width:160px;
		height:200px;
		.title{
			font-size: 14px!important;
			}
		}
	}
	
/********LEADERSHIP********/

.leader-item{
	background:@grayLight;
	margin-bottom: 30px;
	a.readmore{
		display: none;
		}
	.image, .item-content{
		padding:25px;
		}
	.image{
		img{
			max-width:100%!important;
			height:auto;
			border:1px solid rgba(0,0,0,0.2);
			}
		}
	.item-content{
		h5{
			.poppins(32px);
			.uppercase();
			font-weight: 600;
			color:@blue;
			line-height: 1.3;
			}
		h6{
			.poppins(24px);
			font-weight:300;
			color:@red;
			margin-bottom: 15px;
			line-height: 1.3;
			}
		}
	}
	
/*******SERVICES*******/

.service-sub-item{
	.inline-block();
	overflow: hidden;
	position: relative;
	width: 100%;
	max-width:240px;
	height:180px; 
	margin:15px;
	.transition();
	.image{
		position: absolute;
		top:0;
		left:0;
		.scaler();
		width: 100%;
		height: 100%;
		.transition();
		.scale(1);
		z-index: -1;
		}
	.overlay{
		.transition();
		z-index: 10;
		}
	.title{
		display: block;
		width: 100%;
		.poppins(20px);
		.uppercase();
		color: #fff;
		line-height: 1.3;
		position: absolute;
		.centered();
		top:50%;
		transform:translateY(-50%);
		.transition();
		padding:10px;
		z-index: 25!important;
		font-weight: 600;
		}
	&:hover{
		.title{background:rgba(21, 57, 82, 0.8);}
		.image{
			.scale(1.1);
			}
		.overlay{.opacity(0.5);}
		}
	}

.large-text{
	font-size: 20px;
	}
	
/*********CAREERS******/

.video-button{
	float:right;
	display: block;
	width:100%;
	max-width:550px;
	position: relative;
	margin:0 0 40px 30px;
	img{
		width:100%;
		height:auto;
		border:none!important;
		}
	i{
		color:#fff;
		.transition();
		font-size:50px;
		position: absolute;
		display: block;
		top:50%;
		left:50%;
		transform:translateY(-50%) translateX(-50%) scale(1);
		opacity:0.5;
		}
	&:hover{
		i{
			opacity:0.9;
			transform:translateY(-50%) translateX(-50%) scale(1.1);
			}
		}
	}
	
#careers-section{
	background:@aqua;
	padding:80px 0 80px 0;
	}
	
/*************FORMS*********/

.link-form{
	select{
		background:#fff;
		padding:15px;
		.work(18px);
		}
	button.submit{
		margin-top:15px;
		}
	}

/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid @gray;
  background: #fff;
  position: relative;
  border-radius: 0px;
  i.fa-chevron-double-right{
  	position: absolute;
  	right:10px;
  	top:17px;
  	color:@blue;
  	font-size:15px;
  	.rotate(0);
  	.transition();
  	}
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 50px 0 10px;
  line-height: 50px;
  .work(20px);
  height: 50px;
  color: @blue;
}

.selectric .button {
  background: none;
  border-radius: 0px;
  display: block;
  position: absolute;
  right: undefinedpx;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 38px;
  height: 38px;
  color: #444;
  text-align: center;
  font: 0/0 a;
  *font: 38px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #444;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: @blueDark;
  
}

.selectric-hover .selectric {
  border-color: @aqua;
  background:#f1f1f1;
}

.selectric-hover .selectric .button {
  color: #c4c4c4;
}

.selectric-hover .selectric .button:after {
  border-top-color: #c4c4c4;
}

.selectric-open {
  z-index: 9999;
  
}

.selectric-open .selectric {
  border-color: #c4c4c4;
  background:#f1f1f1;
  i.fa-chevron-double-right{.rotate(90deg);}
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
.transition();
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: @grayLight;
  border: 1px solid @grayLight;
  z-index: -1;
  
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0!important;
  margin: 0!important;
  font-size: 12px;
  line-height: 20px!important;
  min-height: 20px;
}

.selectric-items ul>li {
  display: block;
  padding: 8px!important;
  border-top: 1px solid rgba(255,255,255,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.2);
  color: @blue!important;
  cursor: pointer;
  .work(19px);
  .transition();
}

.selectric-items li.selected {
  background: @blue;
  color: #fff!important;
  &.disabled{
  	color:@blue!important;
  	}
}

.selectric-items li.highlighted {
  background: @blueDark;
  color: #fff!important;
}

.selectric-items li:hover {
  background: @blue;
  color: #fff!important;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: @blue !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}
/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:2px solid @beigeLight;
    &.blog{
    	.readmore{display: none;}
    	}
}

.categories{
	padding:30px 0 10px 0;
	.work(16px);
	span{
		.bold();
		color:@blue;
		.uppercase();
		}
	}

.widget{
    margin-bottom:60px;
    padding-bottom: 60px;
    border-bottom:2px solid @beigeLight;
    .work(18px);
	&.list-widget{
		border-bottom: none;
		}
    ul{
    	li{
    		position: relative;
    		padding-left:30px;
    		margin-bottom: 12px;
    		i{
    			font-size:18px;
    			position: absolute;
    			top:3px;
    			left:0;
    			color:@blue;
    			}
    		
    		}
    	&.widget-list{
    			li{
    				padding:0;
    				margin:0;
    				
    				>i{
    					display: none;
    					
    					}
    					>a{
    						display: block;
    						padding:8px 8px 8px 0;
    						.poppins(16px);
    						.uppercase();
    						color:@red;
    						border-bottom: 2px solid @beigeLight;
    						.transition();
    						&:hover{
    							background:@beigeLight;
    							padding: 8px;
    							color:@blueDark;
    							}
    						>i{
    							position: relative;
    							color:@blue;
    							top:0;
    							margin-right:8px;
    							}
    						}
    				}
    			}
    	}
    h5.widget-title{
        .poppins(28px);
        font-weight: 300;
        color:@blue;
        margin-bottom: 20px;
        line-height: 1.3;
        &.icon{
        	padding-top:70px;
        	.centered();
        	background-size:auto 60px!important;
        	&.location{
        		background:url(images/widget-icons/location.svg) no-repeat top center;
        		}
        	&.education{
        		background:url(images/widget-icons/education.svg) no-repeat top center;
        		}
        	&.news{
        		background:url(images/widget-icons/news.svg) no-repeat top center;
        		}
        	&.testimonials{
        		background:url(images/widget-icons/testimonials.svg) no-repeat top center;
        		}
        	strong{
        		display: block;
        		}
        	}
    }
}

.location-item-widget{
	margin-bottom: 30px;
	.acf-map{
		border:2px solid @grayLight;
		height:240px;margin-bottom: 30px;
		}
	.detail{
		i{
			color:@aqua;
			}
		}
	}
 

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
	
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignleft,
.alignright,
.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }